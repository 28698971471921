<template>
  <div class="project-info-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="projectInfoRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="projectInfoForm"
          :rules="projectInfoFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">项目信息</el-divider>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-合同名称 -->
              <ml-input
                prop="contractName"
                label="合同名称:"
                placeholder="请输入合同名称"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.contractName"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-项目编号 -->
              <ml-input
                prop="projectCode"
                label="项目编号:"
                placeholder="请输入项目编号"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.projectCode"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-合同名称 -->
              <ml-input
                prop="projectName"
                label="项目名称:"
                placeholder="请输入项目名称"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.projectName"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-项目类型 -->
              <!-- <ml-input
                prop="projectType"
                label="项目类型:"
                placeholder="请输入项目类型"
                v-model.trim="projectInfoForm.projectType"
              /> -->
              <ml-select
                prop="projectType"
                placeholder="请输入项目类型"
                :options="getProjectType"
                label="项目类型:"
                keyName="name"
                labelName="name"
                valueName="name"
                :disabled="isExamine"
                v-model="projectInfoForm.projectType"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                :disabled="isExamine"
                v-model="projectInfoForm.companyId"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-建设单位（组织机构） -->
              <ml-input
                prop="constructionUnit"
                label="建设单位（组织机构）:"
                placeholder="请输入建设单位（组织机构）"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.constructionUnit"
              />
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <ml-date-picker
                prop="serviceStartTime"
                placeholder="请选择开始时间"
                label="服务开始时间:"
                type="datetime"
                :disabled="isExamine"
                v-model="projectInfoForm.serviceStartTime"
              />
            </el-col>
            <el-col :span="12">
              <ml-date-picker
                prop="serviceEndTime"
                placeholder="请选择结束时间"
                label="服务结束时间:"
                type="datetime"
                :disabled="isExamine"
                v-model="projectInfoForm.serviceEndTime"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 请选择项目地址 -->
              <ml-cascader
                v-if="!isExamine"
                prop="areaArr"
                :props="{ label: 'name', value: 'name' }"
                clearable
                label="项目区域:"
                :options="areaData"
                placeholder="请选择项目区域"
                v-model="projectInfoForm.areaArr"
                style="width: 100%"
              />

              <ml-input
                v-else
                label="项目区域:"
                placeholder=""
                disabled
                :value="projectInfoForm.areaArr.join('/')"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="address"
                label="详细地址:"
                placeholder="请输入详细地址"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.address"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-运维费用 -->
              <ml-input-number
                prop="opsExpenses"
                label="运维费用（元）:"
                placeholder="请输入运维费用"
                :disabled="isExamine"
                v-model="projectInfoForm.opsExpenses"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-专项费用（元） -->
              <ml-input-number
                prop="specialExpenses"
                label="专项费用（元）:"
                placeholder="请输入专项费用"
                :disabled="isExamine"
                v-model="projectInfoForm.specialExpenses"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-累计付款 -->
              <ml-input-number
                prop="paymentTotal"
                label="累计付款（元）:"
                placeholder="请输入累计付款"
                :disabled="isExamine"
                v-model="projectInfoForm.paymentTotal"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-业主负责人 -->
              <ml-input
                prop="proprietor"
                label="业主负责人:"
                placeholder="请输入业主负责人"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.proprietor"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-负责人电话 -->
              <ml-input
                prop="leaderPhone"
                label="负责人电话:"
                placeholder="请输入负责人电话"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.leaderPhone"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-项目经理 -->
              <ml-select
                prop="projectManagerId"
                placeholder="请选择项目经理"
                :options="userOptions"
                label="项目经理:"
                keyName="userId"
                labelName="userName"
                valueName="userId"
                :disabled="isExamine"
                :filterable="true"
                v-model="projectInfoForm.projectManagerId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-项目经理电话 -->
              <ml-input
                prop="managerPhone"
                label="项目经理电话:"
                placeholder="请输入项目经理电话"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.managerPhone"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-主要运维人员 -->
              <!-- <ml-input
                prop="mainOpsUser"
                label="主要服务人员:"
                placeholder="请输入主要服务人员"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.mainOpsUser"
              /> -->
              <ml-select
                prop="serviceUserIds"
                placeholder="请选择主要服务人员"
                :options="userOptions"
                label="主要服务人员:"
                keyName="userId"
                labelName="userName"
                valueName="userId"
                :disabled="isExamine"
                :multiple="true"
                :filterable="true"
                v-model="projectInfoForm.serviceUserIds"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-主要服务人员电话 -->
              <ml-input
                prop="servicePhone"
                label="主要服务人员电话:"
                placeholder="请输入主要服务人员电话"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.servicePhone"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-服务人数 -->
              <ml-input-number
                prop="serviceCount"
                label="服务人数(位):"
                placeholder="请输入服务人数"
                :disabled="isExamine"
                v-model="projectInfoForm.serviceCount"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-运维服务（组织机构） -->
              <ml-input
                prop="serviceOrg"
                label="运维服务（组织机构）:"
                placeholder="请输入运维服务（组织机构）"
                :disabled="isExamine"
                v-model.trim="projectInfoForm.serviceOrg"
              />
            </el-col>
          </el-row>

          <!-- 输入框-服务内容 -->
          <ml-input
            prop="serviceContent"
            label="服务内容:"
            placeholder="请输入服务内容"
            type="textarea"
            :rows="5"
            :disabled="isExamine"
            v-model.trim="projectInfoForm.serviceContent"
          />

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            :showSubmit="!isExamine"
            @click-submit="clickSubmitProjectInfo"
            @click-cancel="clickCancelProjectInfo"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  getTabberData,
  mobilePhoneRegExp,
  searchParams,
  areaArrTOObj,
  tabberHeight,
  formetData,
  getProjectType
} from '@/utils'
export default {
  name: 'ProjectInfoManageAction',
  setup() {
    const { commit, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    // 获取路径参数
    const { edit, move, viewId } = route.query

    // 是否为查看
    const isExamine = computed(() => {
      return viewId ? true : false
    })

    // 设置导航标题文本
    const content = ref(
      edit ? '修改项目信息' : move ? '迁移设备数据' : viewId ? '查看项目信息' : '添加项目信息'
    )
    /* 开始 用户表单 **********************************/
    // 表单配置
    const projectInfoRef = ref()
    const projectInfoForm = reactive({
      contractName: '',
      projectCode: '',
      projectName: '',
      projectType: '',
      areaArr: [],
      address: '',
      companyId: '',
      opsExpenses: 0,
      specialExpenses: 0,
      paymentTotal: 0,
      proprietor: '',
      leaderPhone: '',
      projectManager: '',
      projectManagerId: '',
      managerPhone: '',
      mainOpsUser: '',
      servicePhone: '',
      serviceCount: 0,
      serviceOrg: '',
      constructionUnit: '',
      serviceContent: '',
      serviceStartTime: '',
      serviceEndTime: '',
      serviceUserIdList: [],
      serviceUserIds: []
    })
    // 单位名下拉列表
    const unitOptions = ref([])
    // 用户列表
    const userOptions = ref([])
    // 管理区域配置
    const areaData = ref([])
    // 处理区域
    const getAreaData = id => {
      const list =
        unitOptions.value.filter(item => id === item.companyId).map(item => item.regions) || []
      return list.length > 0 ? (list[0][0].name === '全部' ? list[0][0].children : list[0]) : []
    }
    watch(
      () => projectInfoForm.companyId,
      newvalue => {
        areaData.value = getAreaData(newvalue)
        if (newvalue) {
          getUserListData()
        } else {
          userOptions.value = []
          projectInfoForm.projectManagerId = ''
          projectInfoForm.serviceUserIds = []
        }
      }
    )

    // 获取用户列表
    const getUserListData = () => {
      let data = {
        userId: '',
        projectId: '',
        companyId: projectInfoForm.companyId,
        userName: ''
      }
      dispatch('fetchGetSelectUserByParam', data).then(data => {
        if (data && data.code === 200) {
          userOptions.value = data.data || []
          const user = userOptions.value.find(
            item => item.userId === projectInfoForm.projectManagerId
          )
          if (!user) {
            projectInfoForm.projectManagerId = ''
          }
          projectInfoForm.serviceUserIds = projectInfoForm.serviceUserIds.filter(item => {
            const userId = userOptions.value.find(d => d.userId === item)
            return userId
          })
        }
      })
    }

    // 校验
    const projectInfoFormRules = {
      contractName: [{ required: true, message: '请输合同名称', trigger: 'blur' }],
      projectCode: [{ required: true, message: '请输项目编号', trigger: 'blur' }],
      projectName: [{ required: true, message: '请输项目名称', trigger: 'blur' }],
      projectType: [{ required: true, message: '请输入项目类型', trigger: 'blur' }],
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      serviceStartTime: [{ required: true, message: '请选服务开始时间', trigger: 'blur' }],
      serviceEndTime: [{ required: true, message: '请选服务结束时间', trigger: 'blur' }],
      areaArr: [{ required: true, message: '请选择项目区域', trigger: 'blur' }],
      address: [{ required: true, message: '请选择详细地址', trigger: 'blur' }],
      // opsExpenses: [{ required: true, message: '请输入运维费用', trigger: 'blur' }],
      // specialExpenses: [{ required: true, message: '请输入专项费用', trigger: 'blur' }],
      // paymentTotal: [{ required: true, message: '请输入累计付款', trigger: 'blur' }],
      proprietor: [{ required: true, message: '请输入业主负责人', trigger: 'blur' }],
      leaderPhone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          validator: (role, val) => mobilePhoneRegExp.test(val),
          message: '请输入正确联系电话',
          trigger: 'blur'
        }
      ],
      projectManagerId: [{ required: true, message: '请选择项目经理', trigger: 'blur' }],
      managerPhone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          validator: (role, val) => mobilePhoneRegExp.test(val),
          message: '请输入正确联系电话',
          trigger: 'blur'
        }
      ],
      serviceUserIds: [{ required: true, message: '请选择主要服务人员', trigger: 'blur' }],
      servicePhone: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        {
          validator: (role, val) => mobilePhoneRegExp.test(val),
          message: '请输入正确联系电话',
          trigger: 'blur'
        }
      ],
      // serviceCount: [{ required: true, message: '请输入服务人数', trigger: 'blur' }],
      serviceOrg: [{ required: true, message: '请输入运维服务（组织机构）', trigger: 'blur' }],
      constructionUnit: [
        { required: true, message: '请输入建设单位（组织机构）', trigger: 'blur' }
      ],
      serviceContent: [{ required: true, message: '请输入服务内容', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitProjectInfo = () => {
      projectInfoRef.value.CustomFormRef.validate()
        .then(() => {
          let projectInfoFormParams = searchParams(
            projectInfoForm,
            ['areaArr', 'serviceStartTime', 'serviceEndTime'],
            true
          )
          if (projectInfoForm.areaArr.length > 0) {
            const areaObj = areaArrTOObj(projectInfoForm.areaArr)
            projectInfoFormParams = { ...projectInfoFormParams, ...areaObj }
          }
          if (projectInfoForm.serviceStartTime && projectInfoForm.serviceEndTime) {
            projectInfoFormParams.serviceStartTime = formetData(projectInfoForm.serviceStartTime)
            projectInfoFormParams.serviceEndTime = formetData(projectInfoForm.serviceEndTime)
          }
          const user = userOptions.value.find(
            item => item.userId === projectInfoForm.projectManagerId
          )
          if (user) {
            projectInfoFormParams.projectManager = user.userName
          } else {
            projectInfoFormParams.projectManager = ''
          }
          projectInfoFormParams.serviceUserIdList = projectInfoForm.serviceUserIds.map(item => {
            const userItem = userOptions.value.find(d => d.userId === item)
            return {
              id: '',
              projectId: '',
              userType: '',
              userId: userItem.userId || '',
              userName: userItem.userName || ''
            }
          })
          if (content.value === '添加项目信息') {
            dispatch('fetchAddProjectInfo', projectInfoFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/projectInfoManage' })
              }
            })
          } else {
            let paramsName = ''
            if (edit || viewId) {
              projectInfoFormParams.projectId = edit
              paramsName = 'fetchUpdateProjectInfo'
            } else {
              projectInfoFormParams.projectId = move || viewId
              paramsName = 'fetchMoveProject'
            }
            dispatch(paramsName, projectInfoFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/projectInfoManage' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelProjectInfo = () => {
      router.push({ path: '/projectInfoManage' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []

      if (edit || move || viewId) {
        const projectId = edit || move || viewId
        dispatch('fetchGetProjectInfo', projectId).then(data => {
          if (data && data.code === 200) {
            Object.keys(projectInfoForm).map(item => {
              if (!['areaArr'].includes(item)) {
                projectInfoForm[item] = data.data[item]
              }
            })
            projectInfoForm.serviceUserIds = projectInfoForm.serviceUserIdList.map(d => {
              return d.userId
            })
            if (data.data.province && data.data.city && data.data.area) {
              projectInfoForm.areaArr = [data.data.province, data.data.city, data.data.area]
            }
          }
        })
      }
    })
    return {
      projectInfoRef,
      content,
      projectInfoForm,
      unitOptions,
      areaData,
      projectInfoFormRules,
      clickSubmitProjectInfo,
      clickCancelProjectInfo,
      tabberHeight,
      getProjectType,
      isExamine,
      userOptions,
      getUserListData
    }
  }
}
</script>

<style lang="scss">
.project-info-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
